import { createContext, useMemo, useState } from 'react';

interface ArticleBrowserContextType {
  view: string;
  setView: React.Dispatch<React.SetStateAction<string>>;
  selectedTerms: string[];
  setSelectedTerms: React.Dispatch<React.SetStateAction<string[]>>;
  selectedSearchTerms: string[];
  setSelectedSearchTerms: React.Dispatch<React.SetStateAction<string[]>>;
  searchedTerms: string[];
  setSearchedTerms: React.Dispatch<React.SetStateAction<string[]>>;
}

const ArticleBrowserContext = createContext<ArticleBrowserContextType | undefined>(undefined);

interface ArticleBrowserContextProviderProps {
  children: React.ReactNode;
}

export const ArticleBrowserContextProvider = ({ children }: ArticleBrowserContextProviderProps) => {
  const [view, setView] = useState<string>('PDF');
  const [selectedTerms, setSelectedTerms] = useState<string[]>([]);
  const [selectedSearchTerms, setSelectedSearchTerms] = useState<string[]>([]);
  const [searchedTerms, setSearchedTerms] = useState<string[]>([]);

  const contextValue = useMemo(
    () => ({
      view,
      setView,
      selectedTerms,
      setSelectedTerms,
      selectedSearchTerms,
      setSelectedSearchTerms,
      searchedTerms,
      setSearchedTerms
    }),
    [view, selectedTerms, selectedSearchTerms, searchedTerms]
  );

  return (
    <ArticleBrowserContext.Provider value={contextValue}>
      {children}
    </ArticleBrowserContext.Provider>
  );
};

export default ArticleBrowserContext;
